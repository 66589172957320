import React, { ComponentProps } from 'react';
import Helmet from 'react-helmet';
import { useSiteMetaData } from 'src/hooks/useSiteMetaData';

interface Props extends ComponentProps<typeof Helmet> {
  description?: string;
  lang?: string;
  image?: string;
}

function SEO({ description, lang = 'ko', meta = [], title, image }: Props) {
  const { title: defaultTitle, description: defaultDescription, siteUrl, author, thumbnail } = useSiteMetaData();

  const metaTitle = title ? `${title} | 루비콘` : defaultTitle;
  const metaDescription = description ?? defaultDescription;
  const metaImage = image ?? thumbnail.src;

  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:image`,
      content: metaImage,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      property: `twitter:image`,
      content: metaImage,
    },
    {
      name: 'google-site-verification',
      content: 'YAV889Ju0hL7GVty8_u0QAk_34_T9ZBVGtJeppmDSec',
    },
  ];

  const siteData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: defaultTitle,
    url: siteUrl,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    author: {
      '@type': 'Person',
      name: author,
    },
    creator: {
      '@type': 'Person',
      name: author,
    },
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[...defaultMeta, ...meta]}
    >
      <link rel="icon" href="/favicon.ico" />
      <script type="application/ld+json">{JSON.stringify(siteData)}</script>
    </Helmet>
  );
}

export default SEO;
