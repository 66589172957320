// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentoring-apply-forms-tsx": () => import("./../../../src/pages/mentoring/apply-forms.tsx" /* webpackChunkName: "component---src-pages-mentoring-apply-forms-tsx" */),
  "component---src-pages-mentoring-apply-result-tsx": () => import("./../../../src/pages/mentoring/apply-result.tsx" /* webpackChunkName: "component---src-pages-mentoring-apply-result-tsx" */),
  "component---src-pages-mentoring-intro-tsx": () => import("./../../../src/pages/mentoring/intro.tsx" /* webpackChunkName: "component---src-pages-mentoring-intro-tsx" */)
}

