import React from 'react';
import 'normalize.css';
import smoothscroll from 'smoothscroll-polyfill';
import App from 'src/App';

smoothscroll.polyfill();

export const wrapRootElement = ({ element }) => {
  return <App>{element}</App>;
};
