import { createTheme } from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';
import deepPurple from '@mui/material/colors/deepPurple';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';

const shadows = [
  'none',
  ...Array.from(Array(24)).map((_, i) => `0 5px ${i + 25}px -4px rgba(0, 0, 20, 0.15)`),
] as Shadows;

export const defaultTheme = createTheme({
  typography: {
    fontSize: 14,
  },
  shape: {
    borderRadius: 14,
  },
  palette: {
    primary: {
      main: deepPurple['A200'],
    },
    secondary: {
      main: pink['300'],
    },
    error: {
      main: red['500'],
    },
  },
  shadows,
});
