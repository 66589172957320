import { useEffect } from 'react';

export function useLubyconWelcomeConsole() {
  useEffect(() => {
    console.log('    Connect Your Creativity With the World!');
    console.log(`
    :::      :::    :::::::::::: :::   ::: ::::::::  :::::::: ::::    ::: 
    :+:      :+:    :+::+:    :+::+:   :+::+:    :+::+:    :+::+:+:   :+: 
    +:+      +:+    +:++:+    +:+ +:+ +:+ +:+       +:+    +:+:+:+:+  +:+ 
    +#+      +#+    +:++#++:++#+   +#++:  +#+       +#+    +:++#+ +:+ +#+ 
    +#+      +#+    +#++#+    +#+   +#+   +#+       +#+    +#++#+  +#+#+# 
    #+#      #+#    #+##+#    #+#   #+#   #+#    #+##+#    #+##+#   #+#+# 
    ################## #########    ###    ########  ######## ###    #### 
    `);
    console.log('    If you want to join us, please send email to <help@lubycon.io>');
    console.log('    We will reply you ASAP!');
  }, []);
}
