import { graphql, useStaticQuery } from 'gatsby';
import { SiteMetadata } from 'src/models';
import { FluidImageSharp } from 'src/models/image';

interface QueryResult {
  site: {
    siteMetadata: SiteMetadata;
  };
  thumbnail: FluidImageSharp;
}

export function useSiteMetaData() {
  const {
    site: { siteMetadata },
    thumbnail,
  } = useStaticQuery<QueryResult>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        thumbnail: file(absolutePath: { regex: "/og-image.png/" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return {
    ...siteMetadata,
    thumbnail: thumbnail.childImageSharp.fluid,
  };
}
