import React, { useEffect, PropsWithChildren } from 'react';
import { logger } from '@lubycon/logger';
import SEO from 'components/Utils/SEO';
import { useLubyconWelcomeConsole } from 'hooks/useLubyconWelcomeConsole';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { defaultTheme } from 'src/constants/theme';
import { firebaseConfig } from 'src/constants/firebase';
import { isProduction } from 'src/constants/env';
import { amplitudeAPIKey } from 'src/constants/amplitude';

const App = ({ children }: PropsWithChildren<unknown>) => {
  useLubyconWelcomeConsole();

  useEffect(() => {
    logger.init({
      services: {
        firebase: firebaseConfig,
        amplitude: amplitudeAPIKey,
      },
      mode: isProduction ? 'production' : 'development',
    });
  }, []);

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <SEO />
        <main>{children}</main>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
